<template>
  <div class="about">
    <Banner :list="banners"></Banner>
    <div class="container">
      <div class="passage py-5 scroll_title about-passage">
        <div class="mune-cont flex justify-between align-center">
          <div class="mune-list text-center" v-for="(item, index) in imgs" :key="index" @click="navTitle(index)">
            <div class="cursor-pointer">
              <img :src="item.img" />
              <span class="d-block">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="about-test">
        <h1 class="about-title text-center">{{ $store.state.companyInfo.name }}</h1>
        <span class="about-line m-auto mt-3 mb-6"></span>
        <p class="about-cont-text" v-html="$store.state.companyInfo.intro"></p>
      </div>
    </div>

    <div class="about-main container">
      <div class="course scroll_title">
        <div class="main-title">
          <h1>发展历程</h1>
        </div>
        <div class="course-main">
          <div class="timeline">
            <div class="swiper-container timeline-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide timeline-silde"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/image/about/2020.jpg') + ')',
                    height: '600px',
                  }"
                  data-year="2020"
                >
                  <div class="swiper-slide-content">
                    <span class="timeline-year">2020</span>
                    <h4 class="timeline-title">优普道集团正式成立</h4>
                    <p class="timeline-text">
                      深耕建筑咨询服务12余年，全国员工数达到1000+，拥有30+分子公司，移动APP正式上线，优普道集团正式成立，将为全国建企提供更专业更全面更高效的资质服务。
                    </p>
                  </div>
                </div>
                <div
                  class="swiper-slide timeline-silde"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/image/about/2019.jpg') + ')',
                    height: '600px',
                  }"
                  data-year="2019"
                >
                  <div class="swiper-slide-content">
                    <span class="timeline-year">2019</span>
                    <h4 class="timeline-title">优普道品牌全新升级</h4>
                    <p class="timeline-text">
                      优普道品牌全新升级，资质咨询专业团队获得全面升级，推出新的一体化服务机制。推动“建筑行业人才培训一体化服务+移动互联网”进程，为服务建筑企业提供人才。
                    </p>
                  </div>
                </div>
                <div
                  class="swiper-slide timeline-silde"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/image/about/2018.jpg') + ')',
                    height: '600px',
                  }"
                  data-year="2018"
                >
                  <div class="swiper-slide-content">
                    <span class="timeline-year">2018</span>
                    <h4 class="timeline-title">全国30余家分公司布局完成</h4>
                    <p class="timeline-text">
                      全国30多家分子公司整体布局完成，优普道网站正式启用，人才培训服务走向一体化，注重个人发展及建企后续的人才培养。
                    </p>
                  </div>
                </div>
                <div
                  class="swiper-slide timeline-silde"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/image/about/2016.jpg') + ')',
                    height: '600px',
                  }"
                  data-year="2016"
                >
                  <div class="swiper-slide-content">
                    <span class="timeline-year">2016</span>
                    <h4 class="timeline-title">全新启用优普道新品牌</h4>
                    <p class="timeline-text">
                      全国分子公司合并独立运营，启动全新品牌“优普道”，建工培训的业务全面升级，资质咨询服务正式结合线上和线下进行一体化服务，睿凌环境正式成立。
                    </p>
                  </div>
                </div>
                <div
                  class="swiper-slide timeline-silde"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/image/about/2015.jpg') + ')',
                    height: '600px',
                  }"
                  data-year="2015"
                >
                  <div class="swiper-slide-content">
                    <span class="timeline-year">2015</span>
                    <h4 class="timeline-title">新设7家分公司“专注鱼”品牌化</h4>
                    <p class="timeline-text">
                      建企咨询一体化业务正式走向全国，黑龙江、吉林、江苏、重庆、四川、青海、海南等分公司相继成立并启用，服务于各大中小型企业的“专注鱼”品牌正式成立，为建企提供更多专业咨询服务。
                    </p>
                  </div>
                </div>
                <div
                  class="swiper-slide timeline-silde"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/image/about/2012.jpg') + ')',
                    height: '600px',
                  }"
                  data-year="2012"
                >
                  <div class="swiper-slide-content">
                    <span class="timeline-year">2012</span>
                    <h4 class="timeline-title">江西和内蒙古分公司成立</h4>
                    <p class="timeline-text">
                      江西和内蒙古分公司相继成立，人才猎聘和资质咨询板块规模继续扩大，实现建筑咨询业务的南北发展，为更多建企提供专业高效的咨询服务。
                    </p>
                  </div>
                </div>
                <div
                  class="swiper-slide timeline-silde"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/image/about/2011.jpg') + ')',
                    height: '600px',
                  }"
                  data-year="2011"
                >
                  <div class="swiper-slide-content">
                    <span class="timeline-year">2011</span>
                    <h4 class="timeline-title">首个甘肃分公司成立</h4>
                    <p class="timeline-text">
                      为了给建企提供更便捷更专业的咨询服务，首个甘肃分公司正式成立启用，猎聘及人才项目正式全面启动，为建企提供更多建筑专业人才服务。
                    </p>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-pagination timeline-pagination swiper-pagination-clickable swiper-pagination-bullets"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="culture scroll_title">
        <div class="main-title">
          <h1>企业文化</h1>
          <h3>员工幸福 客户满意 股东增值 公益造福社会</h3>
        </div>
        <div class="culture-main">
          <div class="item">
            <img class="culture-img" src="../assets/image/about/c1.png" alt="" />
            <img class="culture-desc" src="../assets/image/about/desc1.png" alt="" />
          </div>
          <div class="item">
            <img class="culture-img" src="../assets/image/about/c2.png" alt="" />
            <img class="culture-desc" src="../assets/image/about/desc2.png" alt="" />
          </div>
          <div class="item">
            <img class="culture-img" src="../assets/image/about/c3.png" alt="" />
            <img class="culture-desc" src="../assets/image/about/desc3.png" alt="" />
          </div>
          <div class="item">
            <img class="culture-img" src="../assets/image/about/c4.png" alt="" />
            <img class="culture-desc" src="../assets/image/about/desc4.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="honor scroll_title" style="background-color: #FAFAFB;" id="honor">
      <div class="container">
        <div class="text-title">
          <h1 class="mb-1">奖项荣誉</h1>
          <h3>不忘初心 砥砺前行 为建工企业提供更好的服务</h3>
        </div>
        <!-- pc -->
        <div class="position-relative pc-honor">
          <div class="swiper-container swiper-honor">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in honorImgs" :key="index">
                <div class="swper-box">
                  <img :src="item" />
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-button-prev position-absolute top-0 bg-cent"></div>
          <div class="swiper-button-next position-absolute top-0 bg-cent"></div>
        </div>

        <!-- 手机 -->
        <div class="position-relative m-honor">
          <div class="swiper-container swiper-m-honor">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in 1" :key="index">
                <div class="swper-box">
                  <template v-for="li in honorImgs">
                    <img :src="li" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-main container scroll_title">
      <div class="connectUS">
        <div class="main-title">
          <h1>联系我们</h1>
        </div>
        <div class="connect-main">
          <div class="info">
            <h1 class="mb-5">{{ $store.state.companyInfo.name }}</h1>
            <div class="item">
              <div class="img-box">
                <img src="@/assets/image/about/009.png" alt="" />
              </div>
              服务热线：{{ $store.state.companyInfo.business_line }}
            </div>
            <div class="item">
              <div class="img-box">
                <img src="@/assets/image/about/010.png" alt="" />
              </div>
              企业邮箱：{{ $store.state.companyInfo.email }}
            </div>
            <div class="item">
              <div class="img-box">
                <img src="@/assets/image/about/011.png" alt="" />
              </div>
              地址：{{ $store.state.companyInfo.address }}
            </div>
          </div>
          <div class="map">
            <p><img class="mr-1" src="@/assets/image/about/address.png" alt="" />{{ $store.state.companyInfo.address }}</p>
            <div id="map-container" class="map-box"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AMap from "AMap";
import Banner from "@/components/common/banner";
import Swiper from "swiper";

export default {
  components: {
    Banner,
  },
  data() {
    return {
      honorImgs: [
        require("@/assets/image/about/honor1.jpg"),
        require("@/assets/image/about/honor2.jpg"),
        require("@/assets/image/about/honor3.jpg"),
        require("@/assets/image/about/honor4.jpg"),
        require("@/assets/image/about/honor5.jpg"),
        require("@/assets/image/about/honor6.jpg"),
        require("@/assets/image/about/honor7.jpg"),
        require("@/assets/image/about/honor8.jpg"),
      ],
      imgs: [
        {
          img: require("@/assets/image/about/10001.png"),
          name: "关于我们",
        },
        {
          img: require("@/assets/image/about/10002.png"),
          name: "发展历程",
        },
        {
          img: require("@/assets/image/about/10003.png"),
          name: "企业文化",
        },
        {
          img: require("@/assets/image/about/10004.png"),
          name: "奖项荣誉",
        },
        {
          img: require("@/assets/image/about/10005.png"),
          name: "联系我们",
        },
      ],
      stylesList: [],
      banners: {
        title: "一站式智能资质代办平台",
        desc: "超过<span>10万+</span>建筑企业选择的咨询平台",
        list: [require("@/assets/image/banner/4.jpg")],
      },
      introductionList: [],
    };
  },
  methods: {
    navTitle(index) {
      // 用 class="instance_title" 添加锚点
      let jump = document.querySelectorAll(".scroll_title");
      let total = jump[index].offsetTop;
      let distance = document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }

      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }

      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },
    _init_swiper() {
      var swiper = new Swiper(".swiper-honor", {
        slidesPerView: 4,
        // centeredSlides : true,
        autoplay: 2000,
        loop: false,
        pagination: ".swiper-pagination",
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
      });
    },
    _m_swiper() {
      var swiper = new Swiper(".swiper-m-honor", {
        // slidesPerView: 4,
        // centeredSlides : true,
        autoplay: 2000,
        loop: false,
        pagination: ".swiper-pagination",
      });
    },
    enterPagination(e) {
      console.log(e);
    },
    getIntroductionList() {
      this.$api
        .getStylesList({
          launchPage: 90,
        })
        .then((res) => {
          this.introductionList = res.data.list;
          this.$nextTick(() => {
            this.initIntroduceSwiper();
          });
        });
    },
    getStylesList() {
      this.$api
        .getStylesList({
          launchPage: 70,
        })
        .then((res) => {
          this.stylesList = res.data.list;
          this.$nextTick(() => {
            this.initGracefulSwiper();
          });
        });
    },
    getBannerList() {
      this.$api
        .getBannerList({
          // 10首页  20
          launchPage: 70,
          launchFacility: 10,
        })
        .then((res) => {
          this.banners.list = res.data.list;
        });
    },

    initIntroduceSwiper() {
      var galleryTop = new Swiper(".gallery-top", {
        // loop: true,
        autoplay: 2000,
        pagination: ".gallery-pagination",
        paginationClickable: true,
        paginationBulletRender: function(swiper, index, className) {
          var img = document.querySelectorAll(".gallery-slide")[index].getAttribute("data-img");
          return '<img class="' + className + '" src="' + img + '"></>';
        },
      });
      // var galleryThumbs = new Swiper(".gallery-thumbs", {
      //   initialSlide: 1,
      //   spaceBetween: 10,
      //   centeredSlides: true,
      //   slidesPerView: 3,
      //   touchRatio: 0.2,
      //   slideToClickedSlide: true,
      //   loop: false,
      //   direction: "vertical",
      // });
      // galleryTop.params.control = galleryThumbs;
      // galleryThumbs.params.control = galleryTop;
    },
    initTimelineSwiper() {
      var timelineSwiper = new Swiper(".timeline-container", {
        direction: "vertical",
        loop: false,
        autoplay: 2000,
        speed: 1600,
        mousewheelControl: true,
        pagination: ".timeline-pagination",
        paginationBulletRender: function(swiper, index, className) {
          var year = document.querySelectorAll(".timeline-silde")[index].getAttribute("data-year");
          return '<span onmouseover="this.click()" class="' + className + '">' + year + "</span>";
        },
        paginationClickable: true,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        breakpoints: {
          768: {
            direction: "vertical",
          },
        },
      });
    },

    initGracefulSwiper() {
      var gracefulSwiper = new Swiper(".graceful-container", {
        loop: false,
        speed: 1600,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
      });
    },

    initMap() {
      let arr = this.$store.state.companyInfo.lng_lat.split(",");
      let address = this.$store.state.companyInfo.name;
      let map = new AMap.Map("map-container", {
        center: [parseFloat(arr[0]), parseFloat(arr[1])],
        resizeEnable: true,
        zoom: 80,
      });
      var marker = new AMap.Marker({
        position: new AMap.LngLat(parseFloat(arr[0]), parseFloat(arr[1])), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      marker.on("click", function(e) {
        marker.markOnAMAP({
          name: address,
          position: marker.getPosition(),
        });
      });

      map.add(marker);
    },
  },
  computed: {},
  created() {
    this.getBannerList();
    this.getStylesList();
    this.getIntroductionList();
  },
  mounted() {
    this.$nextTick(() => {
      this._init_swiper();
      // this._m_swiper();
      setTimeout(() => {
        this.initTimelineSwiper();
        this.initMap();
      }, 500);
    });

    // this._init_swiper();

    // this.initIntroduceSwiper();
  },
  // watch:{
  //   '$store.state.companyInfo.lng_lat'(){
  //     this.initMap();
  //   }
  // }
};
</script>
<style lang="scss">
@import "@/assets/css/about.scss";

.passage {
  width: 100%;
  position: relative;
  transform: translateY(-30%);
  background: #ffffff;
  z-index: 9;
  border: 1px solid #eeeeee;
  border-image: linear-gradient(#eeeeee, #ffffff) 0 60;
  border-bottom: 0;
}

.mune-cont {
  width: 100%;

  .mune-list {
    width: 25%;

    img {
      width: 87px;
      height: 87px;
      // background: #2070FF;
      border-radius: 16px;
    }

    span {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4d5154;
      margin-top: 25px;
    }
  }
}

.about-test {
  h1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #383d41;
    margin-bottom: 20px;

    h3 {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #686a73;
      line-height: 17px;
    }
  }

  span {
    width: 82px;
    height: 2px;
    background: #2070ff;
    display: block;
  }

  p {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #686a73;
    line-height: 33px;
    text-indent: 2em;
  }

  // margin-bottom: 85px;
}

.honor {
  padding: 80px 0;

  .swiper-container {
    height: 205px;
    margin-top: 46px;
  }

  .swper-box {
    width: 97%;
    height: 204px;
    // background: #FF8400;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: -35px;
    background: url(../assets/image/design/059.png);
    width: 28px;
    height: 52px;
    top: 48%;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: -45px;
    width: 28px;
    height: 52px;
    background: url(../assets/image/design/058.png);
    top: 48%;
  }
}

.m-honor {
  display: none;
}
.culture {
  margin-bottom: 70px;
}
@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 750px) {
}

@media only screen and (max-width: 481px) {
  .culture {
    margin-bottom: 0px;
  }
  .about {
    .about-passage {
      display: none;
    }
    .about-test {
      position: relative;
      top: -30px;
      z-index: 2;
      background-color: #ffffff;
      h1 {
        padding-top: 25px;
        font-size: 15px;
        margin-bottom: 15px;
      }
      span {
        margin-bottom: 25px;
      }
      p {
        font-size: 13px;
      }
    }
    .honor {
      padding-top: 40px;
      padding-bottom: 20px;
      .text-title {
        h1 {
          font-size: 25px !important;
        }
        h3 {
          font-size: 12px !important;
        }
      }
      .pc-honor {
        display: none;
      }
      .m-honor {
        display: block;
        .swiper-container {
          height: auto;
        }
        .swper-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          height: auto;
          img {
            width: 48%;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
</style>
